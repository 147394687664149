import "./css/reset.css"
import "./css/grid.css"
import "./css/flow.css"
import "./css/functional.css"
import "./css/icons.css"

import "./css/typo.css"

import "./css/vars.css"
import "./css/body.css"
import "./css/plugins.css"
import "./css/admin.css"

import "./css/articles.css"
import "./css/buttons.css"
import "./css/cells.css"
import "./css/f3cc.css"
import "./css/embeds.css"
import "./css/filters.css"
import "./css/footer.css"
import "./css/forms.css"
import "./css/masonry.css"
import "./css/menu.css"
import "./css/overlay.css"
import "./css/prose.css"
import "./css/cta.css"
import "./css/troublemaker.css"
import "./css/search.css"
import "./css/map.css"
import "./css/sympi.css"

import { initMapFilter } from "./js/filters"
import { initGallery } from "./js/gallery"
import { initMasonry } from "./js/masonry.js"
import { initScrolledNav } from "./js/nav.js"
import { initOverlayForms } from "./js/overlay.js"
import { initSearch } from "./js/search"
import { onReady } from "./js/utils.js"

onReady(initScrolledNav)
onReady(initOverlayForms)
onReady(initMapFilter)
onReady(initMasonry)
onReady(initSearch)
onReady(initGallery)

const noModernizrButModernizr = () => {
  const el = document.documentElement
  el.classList.remove("no-js")
  el.classList.add("js")
}
noModernizrButModernizr()

// TODO move this into a separate JS file?

const setTargetActive = (load) => {
  const children = load.parentNode.children
  if (children) {
    for (const child of children) {
      child.classList.remove("active")
    }
  }
  load.classList.add("active")
}

onReady(() => {
  document.addEventListener("click", async (e) => {
    const load = e.target.closest("[data-load]")
    const anker = e.target.closest("[data-anker]")
    if (load) {
      e.preventDefault()
      const data = load.dataset
      const title = load.textContent
      const response = await fetch(data.load, {
        headers: { "x-fetch": true },
      })

      if (response.ok) {
        setTargetActive(load)
        const html = await response.text()
        const target = document.getElementById(data.target)
        target.outerHTML = html
        document.title = title
        window.history.pushState(
          {
            html,
            pageTitle: title,
            target: data.target,
          },
          "",
          data.load,
        )

        if (anker) {
          const headerHeight = document.getElementById("header").offsetHeight
          window.scrollTo({
            top: anker.offsetTop - headerHeight,
            behavior: "smooth",
          })
        }
      }
    }
  })

  window.addEventListener("popstate", (e) => {
    if (e.target.location.pathname.indexOf("/vorhaben/") > -1) {
      window.location.pathname = e.target.location.pathname
    }
  })
})
